import React, { useEffect, useState } from "react"
import shuffle from "lodash/shuffle"

import useAllWordpressWpQuote from "../data/allWordpressWpQuote"

const AllQuotes = props => {
  const allWordpressWpQuote = useAllWordpressWpQuote()

  const [quotes, setQuotes] = useState(allWordpressWpQuote.edges)

  useEffect(() => {
    setQuotes(shuffle(quotes))
  }, [])

  if (allWordpressWpQuote.edges.length > 0) {
    return (
      <div className="quotes__wrap">
        {quotes.map(nodeObject => {
          const { node } = nodeObject
          const { id, title } = node
          return (
            <blockquote
              key={id}
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <p dangerouslySetInnerHTML={{ __html: title }} />
            </blockquote>
          )
        })}
      </div>
    )
  }
}

AllQuotes.propTypes = {}

AllQuotes.defaultProps = {}

export default AllQuotes
