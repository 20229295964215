import React from "react"
import clsx from "clsx"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AllQuotes from "../components/AllQuotes"

const Page = props => {
  const { data } = props

  const { wordpressPage } = data

  const { title, content, template, yoast_head } = wordpressPage

  const contentExploder = content.split("<!--more-->")
  return (
    <>
      <Layout
        type="page"
        template={template}
        pathname={props.location.pathname}
      >
        <SEO
          title="home"
          bodyClasses={clsx("page", {
            "page-template-page-about": template === "page-about.php",
            "page-template-page-quotes": template === "page-quotes.php",
          })}
          yoast_head={yoast_head}
        />

        <div className="inner">
          {template === "page-quotes.php" ? (
            <>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <AllQuotes />
            </>
          ) : (
            <>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div
                className="perex"
                dangerouslySetInnerHTML={{ __html: contentExploder[0] }}
              />
              <div dangerouslySetInnerHTML={{ __html: contentExploder[1] }} />
            </>
          )}
        </div>
      </Layout>
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      template
      yoast_head
    }
  }
`
